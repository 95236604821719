import pako from 'pako'
import JSZip from 'jszip'
import { Buffer } from 'buffer'
import { differenceWith, isEmpty, isEqual, sortBy } from 'lodash-es'
import { strFromU8, gunzipSync, decompressSync } from 'fflate'

/**
 * Compress a file object into a zip file
 * @param {File} file in-memory file object
 * @param {String} name file name to set in the zipped file
 * @param {Number} level number between 1 (best speed) and 9 (best compression)
 */
export async function compressFile(file, name = 'zippedFile.tmp', level = 1) {
  const zip = new JSZip()
  zip.file(name, file)
  return zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: { level }
  })
}

/**
 * Compress data of any non-null type.
 * Use compression level 1 which prefers speed over compression size
 * Note: Code tested with object and array of objects but should also work with other types
 *
 * @param {*} data - non null object of any type
 * @returns compressed base64 encoded string
 */
export function compress(data, level = 1) {
  if (!data) return null
  const deflated = pako.deflate(JSON.stringify(data), { level })
  return Buffer.from(deflated).toString('base64')
}

export function compressString(data, level = 1) {
  if (!data) return null
  const deflated = pako.deflate(data, { level })
  return Buffer.from(deflated).toString('base64')
}

/**
 * Decompress a json object or array of objects that was compressed using compress() above
 *
 * @param {*} data - base64 encoded string
 * @returns decompressed json data
 */
export function decompressJson(str, level = 1) {
  if (!str) return null
  const buffer = Buffer.from(str, 'base64')
  const jsonStr = pako.inflate(buffer, { to: 'string', level })
  return JSON.parse(jsonStr)
}

export function decompressPeople(data) {
  const uint8Array = new Uint8Array(data)
  const decompressed = gunzipSync(uint8Array)
  return JSON.parse(strFromU8(decompressed))
}

export function decompressString(str, level = 1) {
  if (!str) return null
  const buffer = Buffer.from(str, 'base64')
  const decompStr = pako.inflate(buffer, { to: 'string', level })
  return decompStr
}

export function decompressBufferFflate(buffer) {
  const uint8Array = new Uint8Array(buffer)
  const decompressed = decompressSync(uint8Array)
  const decompressedStr = strFromU8(decompressed)
  return decompressedStr
}

// Alternative to pako utility functions above which is both faster and compresses more
// Source: https://github.com/101arrowz/fflate
export function decompressBase64ToJsonFflate(base64Str) {
  const uint8Array = Buffer.from(base64Str, 'base64')
  const decompressed = gunzipSync(uint8Array)
  const decompressedStr = strFromU8(decompressed)
  return JSON.parse(decompressedStr)
}

export function decompressBufferToJsonFflate(buffer) {
  const uint8Array = new Uint8Array(buffer)
  const decompressed = gunzipSync(uint8Array)
  const decompressedStr = strFromU8(decompressed)
  return JSON.parse(decompressedStr)
}

export function isGenericArrayEqual(x, y) {
  if (!x && !y) return true
  if (!x && y) return false
  if (x && !y) return false
  if (x.length !== y.length) return false

  const difference = differenceWith(x, y, (obj1, obj2) => {
    return isEqual(obj1, obj2)
  })

  return isEmpty(difference)
}

export function isArrayEqual(x, y) {
  const difference = differenceWith(x, y, (obj1, obj2) => {
    return obj1.id === obj2.id && obj1.parentId === obj2.parentId
  })

  return isEmpty(difference)
}

export function splitStringBySecondSpace(str) {
  const words = str.split(' ')
  const result = []
  let currentString = ''

  for (let i = 0; i < words.length; i++) {
    if ((i + 1) % 3 === 0) {
      // Split on every second space (every 3rd word)
      result.push(currentString.trim())
      currentString = ''
    }
    currentString += words[i] + ' '
  }

  // Add the remaining part of the string
  if (currentString !== '') {
    result.push(currentString.trim())
  }

  return result
}

export function areArraysOfStringsEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false
  }

  const sortedArr1 = [...arr1].sort()
  const sortedArr2 = [...arr2].sort()

  return sortedArr1.every((value, index) => value === sortedArr2[index])
}

export async function sleep(durationMs) {
  return new Promise((resolve) => {
    setTimeout(resolve, durationMs)
  }).then((response) => {
    return response
  })
}

/**
 * Allows only numeric input in the event.
 * @param {Event} evt - The input event to handle.
 * @returns {void}
 */
export function isKeyNumeric(evt) {
  const event = evt || window.event
  const key = event.keyCode || event.which

  if (
    (!event.shiftKey &&
      !event.altKey &&
      !event.ctrlKey &&
      // numbers
      key >= 48 &&
      key <= 57) ||
    // Numeric keypad
    (key >= 96 && key <= 105) ||
    // Backspace and Tab and Enter
    key === 8 ||
    key === 9 ||
    key === 13 ||
    // Home and End
    key === 35 ||
    key === 36 ||
    // left and right arrows
    key === 37 ||
    key === 39 ||
    // Del and Ins
    key === 46 ||
    key === 45
  ) {
    // input is VALID
    return true
  } else {
    // input is INVALID
    event.returnValue = false
    if (event.preventDefault) event.preventDefault()
    return false
  }
}
/**
 * Download a file sent from expressJs using res.download(...).
 * Return the file as an ArrayBuffer, which can be downloaded directly to the user using 'downloadjs'.
 */
export async function readFile(file) {
  return new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onload = () => resolve(fileReader.result)
    fileReader.readAsArrayBuffer(file)
  })
}

export function sortDataByGivenOrder({ data, order, sortByProperty }) {
  // Create a lookup object from the order array for sorting
  const orderLookup = order.reduce((acc, cur) => {
    acc[cur.name] = cur.order
    return acc
  }, {})
  // Sort the data array based on the order defined in the lookup
  const sortedData = sortBy(data, (item) => orderLookup[item[sortByProperty]])
  return sortedData
}
