import { unref } from 'vue'

export function personCustomFields(boardId, personId) {
  return ['custom-fields-values', { boardId: unref(boardId), personId: unref(personId) }]
}

export function uniqueCustomFieldsValues(boardId, uid, projectId) {
  return [
    'custom-fields-values-unique',
    { boardId: unref(boardId), uid: unref(uid), projectId: unref(projectId) }
  ]
}

export function personData(boardId, personId) {
  return ['person-data', { boardId: unref(boardId), personId: unref(personId) }]
}

export function personPlanHistory(boardId, personId) {
  return ['person-plan-history', { boardId: unref(boardId), personId: unref(personId) }]
}

export function scenarioStatus(boardId, status) {
  return ['scenario-status', { boardId: unref(boardId), status: unref(status) }]
}
