import { createIdbPersister } from '@/lib/QueryPersisterIdb.js'
import { bulkUpdate, getTypes, getUniqueValues } from '@/services/custom-fields-api.service.js'
import { personCustomFields, uniqueCustomFieldsValues } from '@/services/queryKeys.service'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import { useStore } from 'vuex'

export function useCustomFields(boardId, projectId = null) {
  const persister = createIdbPersister()
  const queryClient = useQueryClient()
  const { getters } = useStore()

  const uid = computed(() => getters.uid)

  const typesQueryKey = ['custom-fields-types', { uid: unref(uid), boardId: unref(boardId) }]
  const { data: types, isPending: isLoadingTypes, refetch: refetchTypes } = useQuery({
    queryKey: typesQueryKey,
    queryFn: () => getTypes({ boardId: unref(boardId) }),
    persister
  })

  const uniqueValuesQueryKey = uniqueCustomFieldsValues(boardId, uid, projectId)
  const { data: uniqueValues, isPending: isLoadingUniqueValues } = useQuery({
    queryKey: uniqueValuesQueryKey,
    queryFn: () => getUniqueValues({ boardId: unref(boardId), projectId: unref(projectId) }),
    persister
  })

  const { mutate: mutateValuesBulk } = useMutation({
    mutationFn: ({ peopleIds, values }) => bulkUpdate({ boardId, peopleIds, values }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: uniqueValuesQueryKey })
      variables.peopleIds.forEach((personId) => {
        queryClient.invalidateQueries({ queryKey: personCustomFields(boardId, personId) })
      })
    }
  })

  async function bulkUpdateValues({ peopleIds, values }) {
    return mutateValuesBulk({ peopleIds, values })
  }

  const handleTypesRefetch = async () => {
    await refetchTypes()
  }

  return {
    types,
    uniqueValues,
    isLoadingTypes,
    isLoadingUniqueValues,
    bulkUpdateValues,
    handleTypesRefetch
  }
}

export function useCustomFieldsHelper(boardId) {
  const queryClient = useQueryClient()

  async function invalidateAll() {
    await Promise.allSettled([
      queryClient.invalidateQueries({
        queryKey: ['custom-fields-values-unique', { boardId: unref(boardId) }]
      }),
      queryClient.invalidateQueries({
        queryKey: ['custom-fields-types', { boardId: unref(boardId) }]
      })
    ])
  }

  return {
    invalidateAll
  }
}
