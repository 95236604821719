<template>
  <div ref="menu" class="relative h-full" data-test="home">
    <button
      class="flex items-center justify-center rounded-lg px-[14px] py-2 text-sm font-medium"
      :class="[
        open
          ? 'bg-bluedark-50 text-bluedark-700 hover:bg-bluedark-50'
          : 'text-gray-500 hover:bg-bluedark-50 hover:text-bluedark-700'
      ]"
      :data-test="'home-dropdown-button'"
      @click="open = !open"
      @mouseover="open = true"
    >
      <component :is="Icon" class="h-5 w-5 transition-colors" />
    </button>

    <transition
      enter-active-class="transition duration-100 origin-top-left"
      enter-from-class="transform opacity-0 scale-50"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition duration-75 origin-bottom-right"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-50"
    >
      <div
        v-if="open"
        class="absolute left-0 z-[100] mt-2.5 min-w-fit rounded-lg border bg-white shadow-lg"
        @mouseleave="closeAllSubmenus"
      >
        <ul class="overflow-y-auto rounded-lg p-2">
          <!-- Main menu items -->

          <!-- Home -->
          <li
            class="mb-1"
            :class="[
              $route.fullPath === landingUrl
                ? 'rounded-lg bg-bluedark-50 text-bluedark-500 hover:bg-bluedark-100'
                : 'hover:rounded-lg hover:bg-bluedark-50 hover:text-bluedark-700'
            ]"
            @mouseover="toggleSubmenu('home')"
          >
            <router-link
              :to="landingUrl"
              class="flex w-full cursor-pointer items-center whitespace-nowrap py-2.5 pl-4 pr-6 text-left text-sm font-normal"
              data-test="home"
              @click="open = false"
            >
              <component :is="IconHome" class="mr-2 h-4 w-4" />
              <span>Home</span>
            </router-link>
          </li>

          <!-- Settings -->
          <li
            v-if="isAdmin"
            class="mb-1 flex w-full cursor-pointer items-center whitespace-nowrap py-2.5 pl-4 pr-6 text-left text-sm font-normal"
            :class="[
              $route.fullPath === navigationConfig[MenuItems.Settings].url ||
              $route.fullPath === navigationConfig[MenuItems.Access].url
                ? 'rounded-lg bg-bluedark-50 text-bluedark-500 hover:bg-bluedark-100'
                : 'hover:rounded-lg hover:bg-bluedark-50 hover:text-bluedark-700'
            ]"
            @mouseover="toggleSubmenu('settings')"
          >
            <component :is="CogIcon" class="mr-2 h-4 w-4" />
            <span class="pr-4">Settings</span>
            <ChevronRightIcon class="ml-auto h-4 w-4" />
          </li>

          <!-- Contact support -->
          <li class="mb-1 hover:rounded-lg hover:bg-bluedark-50 hover:text-bluedark-700">
            <a
              href="mailto:support@agentnoon.com"
              class="flex w-full cursor-pointer items-center whitespace-nowrap py-2.5 pl-4 pr-6 text-left text-sm font-normal"
              data-test="contact-support"
              @mouseover="toggleSubmenu('contact')"
            >
              <component :is="MailIcon" class="mr-2 h-4 w-4" />
              <span class="pr-4">Contact Support</span>
            </a>
          </li>

          <!-- Help center -->
          <li class="hover:rounded-lg hover:bg-bluedark-50 hover:text-bluedark-700">
            <a
              href="https://docs.agentnoon.com/help-center/articles"
              target="blank"
              class="flex w-full cursor-pointer items-center whitespace-nowrap py-2.5 pl-4 pr-6 text-left text-sm font-normal"
              data-test="help-center"
              @mouseover="toggleSubmenu('help')"
            >
              <component :is="IconHelp" class="mr-2 h-4 w-4" />
              <span class="pr-4">Help Center</span>
            </a>
          </li>
        </ul>
        <div
          v-if="routeOptions.length > 0 && activeSubmenu === 'settings'"
          class="absolute left-full top-2 z-40 mt-4 min-w-fit rounded-lg border bg-white shadow-lg"
        >
          <ul class="overflow-y-auto rounded-lg p-2">
            <li
              v-for="(route, index) in routeOptions"
              :key="route.name"
              :class="{ 'mb-1': index !== routeOptions.length - 1 }"
            >
              <router-link
                v-if="route.show"
                :to="route.url"
                class="flex w-full cursor-pointer items-center whitespace-nowrap py-2.5 pl-4 pr-6 text-left text-sm font-normal hover:rounded-lg hover:bg-bluedark-50 hover:text-bluedark-700"
                :data-test="route.dataTest"
                :class="[
                  $route.fullPath === route.url
                    ? 'rounded-lg bg-gray-100 text-agentnoon-primary'
                    : 'text-gray-900'
                ]"
                @click="open = false"
              >
                <component :is="route.icon" class="mr-2 h-4 w-4" />
                <span>{{ route.name }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import Icon from '@/assets/untitled-ui/menu-01.svg'
import IconHome from '@/assets/untitled-ui/home-smile.svg'
import IconHelp from '@/assets/untitled-ui/help-circle.svg'
import { onClickOutside } from '@vueuse/core'
import { ref, computed } from 'vue'
import { MenuItems } from '@/utils/navigationData'
import useNavigation from '@/hooks/use-navigation'
import useBoard from '@/hooks/use-board'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { MailIcon, CogIcon } from '@heroicons/vue/outline'
import { useStore } from 'vuex'

const open = ref(false)
const menu = ref(null)
const activeSubmenu = ref(null)

const { navigationConfig } = useNavigation()
const { boardId } = useBoard()

const { getters } = useStore()

const isAdmin = computed(() => getters.isAdmin(boardId.value))

const landingUrl = computed(() => `/${boardId.value}`)

const routeOptions = computed(() => [
  {
    ...navigationConfig.value[MenuItems.Settings],
    dataTest: 'settings'
  },
  {
    ...navigationConfig.value[MenuItems.Access],
    dataTest: 'access-control'
  }
])

const toggleSubmenu = (submenu) => {
  activeSubmenu.value = submenu
}

const closeAllSubmenus = () => {
  open.value = false
  activeSubmenu.value = null
}

onClickOutside(menu, closeAllSubmenus)
</script>
