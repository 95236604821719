import { nanoid } from 'nanoid'
import ShortUniqueId from 'short-unique-id'

const { randomUUID } = new ShortUniqueId({ length: 5 })

/**
  Generates an ID for a board document using nanoid
*/
const createBoardId = () => {
  return `bd_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a company using nanoid
*/
const createCompanyId = () => {
  return `company_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a people document using nanoid
*/
const createPersonId = () => {
  return `p_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a requisitions document using nanoid
*/
const createRequisitionRequestId = () => {
  return `hr_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a squad document using nanoid
*/
const createSquadId = () => {
  return `sq_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

const createPlanId = () => {
  return `pl_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a plan_logs document using nanoid
*/
const createPlanLogId = () => {
  return `pl_log_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a plan_logs document using nanoid
*/
const createCommentId = () => {
  return `cmt_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a notification document using nanoid
*/
const createNotificationId = () => {
  return `notif_${nanoid().replace(/-/g, 'DK').replace(/_/, 'AN')}`
}

const createGenericId = () => {
  return nanoid().replace(/-/g, 'DK').replace(/_/, 'AN')
}

/**
 * Creates an activity analysis ID based on the given entry.
 * @param {string} entry - The entry to create the ID from.
 * @returns {string} The activity analysis ID.
 */
const createActivityAnalysisId = (entry) => {
  return `p_${entry}_${randomUUID()}`
}

export {
  createBoardId,
  createCompanyId,
  createPersonId,
  createRequisitionRequestId,
  createSquadId,
  createPlanId,
  createNotificationId,
  createPlanLogId,
  createGenericId,
  createActivityAnalysisId,
  createCommentId
}

export default {
  createBoardId,
  createCompanyId,
  createPersonId,
  createRequisitionRequestId,
  createSquadId,
  createPlanId,
  createNotificationId,
  createPlanLogId,
  createGenericId,
  createActivityAnalysisId,
  createCommentId
}
