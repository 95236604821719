export const MappingDict = {
  currency: 'currency',
  customFields: 'customFields',
  department: 'department',
  email: 'email',
  employeeId: 'employeeId',
  employeeStatus: 'employeeStatus',
  indirectConnections: 'indirectConnections',
  managerId: 'managerId',
  name: 'name',
  officeLocation: 'officeLocation',
  payPer: 'payPer',
  performance: 'performance',
  photoURL: 'photoURL',
  role: 'role',
  salary: 'salary',
  salaryType: 'salaryType',
  startDate: 'startDate',
  succession: 'succession',
  terminationDate: 'terminationDate',
  terminationReasons: 'terminationReasons',
  position: 'position',
  skill: 'skill'
}

export const Mapping = [
  MappingDict.currency,
  MappingDict.customFields,
  MappingDict.department,
  MappingDict.email,
  MappingDict.employeeId,
  MappingDict.employeeStatus,
  MappingDict.indirectConnections,
  MappingDict.managerId,
  MappingDict.name,
  MappingDict.officeLocation,
  MappingDict.payPer,
  MappingDict.performance,
  MappingDict.photoURL,
  MappingDict.role,
  MappingDict.salary,
  MappingDict.salaryType,
  MappingDict.startDate,
  MappingDict.succession,
  MappingDict.terminationDate,
  MappingDict.terminationReasons,
  MappingDict.skill
]

export const exemptMappingRequirement = [
  MappingDict.startDate,
  MappingDict.terminationDate,
  MappingDict.position
]
