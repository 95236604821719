import { ACCESS_LEVELS_DICT, userHasRouteAccess } from '@/lib/Access.js'
import store from '@/store/index.js'
import { FeatureAccess, MenuItems } from '@/utils/navigationData.js'
import firebase from 'firebase/compat/app'
import { createRouter, createWebHistory } from 'vue-router'
import { getBoard } from '@/services/boards-api.service.js'
import { setAPIUrl } from '@/services/api.service.js'

const isProduction = process.env.NODE_ENV === 'production'
const baseUrl = isProduction ? '/' : '/'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue'),

    beforeEnter: (to, from, next) => {
      let alreadyTriggered = false
      firebase.auth().onAuthStateChanged((user) => {
        if (alreadyTriggered) return
        alreadyTriggered = true
        if (user !== null) {
          next({ name: 'Landing' })
        } else next()
      })
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPasswordView.vue'),
    beforeEnter: (to, from, next) => {
      let alreadyTriggered = false
      const oobCode = to.query.oobCode

      if (!oobCode) {
        return next({ name: 'Home' })
      }

      firebase.auth().onAuthStateChanged((user) => {
        if (alreadyTriggered) return
        alreadyTriggered = true

        if (user !== null) {
          return next({ name: 'Landing' })
        }

        next()
      })
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('@/views/SignUpView.vue')
  },
  {
    path: '/setup-user',
    name: 'SetupUser',
    component: () => import('@/views/SetupUserView.vue'),
    meta: {
      title: 'Setup user'
    }
  },
  {
    path: '/login-redirect',
    name: 'LoginRedirect',
    component: () => import('@/views/LoginRedirect.vue')
  },
  {
    path: '/hub/:boardId',
    name: 'Hub',
    component: () => import('@/views/hub/HubLayout.vue'),
    meta: {
      requiresAuth: true,
      title: 'Hub',
      permittedRoles: FeatureAccess[MenuItems.Hub]
    }
  },
  {
    path: '/:boardId?',
    name: 'Landing',
    component: () => import('@/views/LandingPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:boardId/:projectId',
    name: 'Project View',
    component: () => import('@/views/ProjectView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/board/:boardId',
    name: 'Board',
    component: () => import('@/views/OrgChartV2.vue'),
    meta: {
      requiresAuth: true,
      title: 'Org Chart',
      blockedRoles: [ACCESS_LEVELS_DICT.NO_ACCESS]
    }
  },
  {
    path: '/overview/:boardId/:planId?',
    name: 'Org Overview',
    component: () => import('@/views/TacticalView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Org Chart',
      blockedRoles: [ACCESS_LEVELS_DICT.NO_ACCESS]
    }
  },
  {
    path: '/organization-units/:boardId/:planId?',
    name: 'Organization Units',
    component: () => import('@/views/OrganizationUnitsView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Organization Units',
      blockedRoles: [ACCESS_LEVELS_DICT.NO_ACCESS]
    }
  },
  {
    path: '/graph/:boardId',
    name: 'Org Graph',
    component: () => import('@/views/GraphView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Org Chart Graph',
      blockedRoles: [ACCESS_LEVELS_DICT.NO_ACCESS]
    }
  },
  {
    path: '/import/:boardId',
    name: 'MainOrgImportData',
    component: () => import('@/views/ImportData.vue'),
    meta: {
      requiresAuth: true,
      title: 'Data Import'
    }
  },
  {
    path: '/import/:boardId/:planId',
    name: 'PlanImportData',
    component: () => import('@/views/ImportData.vue'),
    meta: {
      requiresAuth: true,
      title: 'Data Import'
    }
  },
  {
    path: '/one-drive/:boardId',
    name: 'MainOrgImportFromOneDrive',
    component: () => import('@/views/ImportFromOneDrive.vue'),
    meta: {
      requiresAuth: true,
      title: 'Import From OneDrive'
    }
  },
  {
    path: '/one-drive/:boardId/:planId',
    name: 'PlanImportFromOneDrive',
    component: () => import('@/views/ImportFromOneDrive.vue'),
    meta: {
      requiresAuth: true,
      title: 'Import From OneDrive'
    }
  },
  {
    path: '/import/csv/:boardId',
    name: 'MainOrgImportCSV',
    component: () => import('@/views/ImportCSVFiles.vue'),
    meta: {
      requiresAuth: true,
      title: 'CSV Import'
    }
  },
  {
    path: '/import/csv/:boardId/:planId',
    name: 'PlanImportCSV',
    component: () => import('@/views/ImportCSVFiles.vue'),
    meta: {
      requiresAuth: true,
      title: 'CSV Import'
    }
  },
  {
    path: '/formulas/:boardId',
    name: 'Formulas',
    component: () => import('@/views/FormulasView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Formulas',
      permittedRoles: FeatureAccess[MenuItems.Directory]
    }
  },
  {
    path: '/table/:boardId',
    name: 'Table',
    component: () => import('@/views/OrgTable.vue'),
    meta: {
      requiresAuth: true,
      title: 'Directory',
      permittedRoles: FeatureAccess[MenuItems.Directory]
    }
  },
  {
    path: '/integration/:boardId',
    name: 'Main Integration',
    component: () => import('@/views/IntegrationView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Integration',
      permittedRoles: FeatureAccess[MenuItems.Plans]
    }
  },
  {
    path: '/integration/:boardId/:planId',
    name: 'Plan Integration',
    component: () => import('@/views/IntegrationView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Integration',
      permittedRoles: FeatureAccess[MenuItems.Plans]
    }
  },
  {
    path: '/plans/:boardId/:projectId?',
    name: 'Plans',
    component: () => import('@/views/ScenariosList.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Scenarios',
      permittedRoles: FeatureAccess[MenuItems.Plans]
    }
  },
  {
    path: '/plan/:boardId/:planId',
    name: 'Plan View',
    component: () => import('@/views/PlanView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Scenario',
      permittedRoles: FeatureAccess[MenuItems.Plans]
    }
  },
  {
    path: '/forecast/:boardId',
    name: 'Forecast',
    component: () => import('@/views/ForecastView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Forecast',
      permittedRoles: FeatureAccess[MenuItems.Forecast]
    }
  },
  {
    path: '/invite-access/:boardId',
    name: 'InviteAccessUsers',
    component: () => import('@/views/AccessControlView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Access and Invite',
      permittedRoles: FeatureAccess[MenuItems.Access]
    }
  },
  {
    path: '/plan/merge/:boardId',
    name: 'PlanMerge',
    component: () => import('@/views/PlanMerge.vue'),
    meta: {
      requiresAuth: true,
      title: 'Merge scenarios',
      permittedRoles: FeatureAccess[MenuItems.Plans]
    }
  },
  {
    path: '/analytics/:boardId',
    name: 'Analytics',
    component: () => import('@/views/AnalyticsView.vue'),
    meta: {
      title: 'Analytics',
      permittedRoles: FeatureAccess[MenuItems.Analytics]
    }
  },
  {
    path: '/verify',
    name: 'VerifyEmail',
    component: () => import('@/views/VerifyEmail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/oops',
    name: 'No Access',
    component: () => import('@/views/InsufficientAccess.vue'),
    meta: {
      title: 'Access denied'
    }
  },
  {
    path: '/share/:planId',
    name: 'SharedPlan',
    component: () => import('@/views/SharedPlan.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('@/views/PageNotFound.vue')
  },
  {
    path: '/settings/:boardId',
    name: 'Settings',
    component: () => import('@/views/SettingsView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Settings',
      permittedRoles: FeatureAccess[MenuItems.Settings]
    }
  },
  {
    path: '/variance/:boardId',
    name: 'Variance Tracking',
    component: () => import('@/views/VarianceTracking.vue'),
    meta: {
      requiresAuth: true,
      title: 'Variance Tracking',
      permittedRoles: FeatureAccess[MenuItems.Forecast]
    }
  },
  {
    path: '/pack/:boardId/:planId/:packId',
    name: 'Scenario Slide Pack',
    component: () => import('@/views/SlidePackView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Slide Pack',
      permittedRoles: FeatureAccess[MenuItems.Hub]
    }
  },
  {
    path: '/pack/:boardId/:packId',
    name: 'Slide Pack',
    component: () => import('@/views/SlidePackView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Slide Pack',
      permittedRoles: FeatureAccess[MenuItems.Hub]
    }
  }
]

const router = createRouter({
  history: createWebHistory(baseUrl),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath.substr(0, 2) === '/#') {
    const path = to.fullPath.substr(2)
    next(path)
    return
  }
  if (to.fullPath.includes('/v2/')) {
    const path = to.fullPath.replace('/v2/', '/')
    next(path)
    return
  }

  if (to.meta.requiresAuth) {
    if (
      store.getters.isLoggedIn &&
      !store.getters.user?.emailVerified &&
      to.name !== 'VerifyEmail'
    ) {
      return next({ name: 'SetupUser' })
    }
  }

  next()
})

// Use afterEach to allow dynamic titles in routes which can be set with `beforeEnter`
router.afterEach((to) => {
  window.mixpanel.track(`route_${to.name}`)
  document.title = to.meta?.title || 'Agentnoon'
})

router.beforeResolve((to, from, next) => {
  // If the route doesn't have any permitted or blocked roles, we don't need to check access
  if (to.meta.blockedRoles === undefined && to.meta.permittedRoles === undefined) {
    return next()
  }

  const userRole = store.getters.role(to.params.boardId)

  if (
    userRole &&
    userHasRouteAccess({
      role: userRole,
      permittedRoles: to.meta.permittedRoles,
      blockedRoles: to.meta.blockedRoles
    })
  ) {
    return next()
  }

  // Flag to stop `onAuthStateChanged` from running multiple times
  let alreadyTriggered = false

  const unSub = firebase.auth().onAuthStateChanged(async (user) => {
    if (alreadyTriggered) {
      unSub()
      return
    }

    if (!user) {
      //redirect to login page and save the current route
      return next({ name: 'Home', query: { redirect: to.fullPath } })
    }

    await store.dispatch('setUserAuth', user)
    setAPIUrl()

    // If the user object is unavailable - happens when user refreshes page - fetch board and get users role
    alreadyTriggered = true

    let userRole = null
    try {
      const currBoard = await getBoard({ boardId: to.params.boardId })
      const userAccessObj = currBoard.accessLevels.find((accessObj) => accessObj.uid === user.uid)
      userRole = userAccessObj.accessLevel

      const userHasAccess = userHasRouteAccess({
        role: userRole,
        permittedRoles: to.meta.permittedRoles,
        blockedRoles: to.meta.blockedRoles
      })

      if (!userHasAccess) return next({ name: 'No Access' })
      return next()
    } catch (e) {
      console.log(e)
      return next({ name: 'No Access' })
    } finally {
      alreadyTriggered = false
      unSub()
    }
  })
})

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    window.location = to.fullPath
  }
})
export default router
