import { getMapping, saveMapping, sync } from '@/services/import-api.service.js'
import { updateSettings } from '@/services/settings-api.service.js'

//TODO: module without state - doesn't need to be wrapped in vuex
const actions = {
  async backendSync(context, boardId) {
    return sync({ boardId })
  },

  async fetchSavedImportMappings(context, boardId) {
    return getMapping({ boardId })
  },

  async setGoogleSheetsAutoSync(context, { boardId, autoSync }) {
    try {
      await updateSettings({ boardId, settings: { isGoogleSheetsAutoSyncOn: autoSync } })

      return {
        status: true,
        msg: 'Successfully saved'
      }
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },

  async saveImportMappings(context, { boardId, mapping, filePath }) {
    await saveMapping({ boardId, mapping, filePath })
  }
}

export default {
  state: {},
  mutations: {},
  getters: {},
  actions,
  modules: {}
}
