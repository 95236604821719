import { getOperatingModel, saveOperatingModel } from '@/services/operating-model-api.service'
import _ from 'lodash'
import { createActivityAnalysisId } from '@/lib/idCreator.js'
import { fetchValues } from '@/services/custom-fields-api.service.js'
import Big from 'big.js'

// return activity analysis data
const getDefaultState = () => {
  return {
    operatingModel: {
      p_operating_model: {
        meta: {
          tasks: []
        },
        parent: '_root',
        depth: 1
      }
    },
    roleFTEMap: {}
  }
}

const state = getDefaultState()

const getters = {
  getOperatingModel: (_state) => _state.operatingModel,
  getBusinessProcessById: (_state) => (id) => {
    return _state.operatingModel[id]
  },
  getAddedPeople: (_state) => (id, taskIndex) => {
    return _state.operatingModel[id].meta.tasks[taskIndex].people.map((person) => person.name)
  },
  getActivityCategorySuggestions: (_state) =>
    _state.operatingModel.p_operating_model.activityCategorySuggestions,
  getChangeEnablerSuggetions: (_state) =>
    _state.operatingModel.p_operating_model.changeEnablerSuggestions,
  getAllBusinessProcessNames: (_state) => {
    return Object.entries(_state.operatingModel)
      .filter(([key]) => key !== 'p_operating_model')
      .map(([key, value]) => ({
        key,
        name: value.name
      }))
  },
  getMaxDepthLevel: (_state) => {
    return Math.max(...Object.values(_state.operatingModel).map((bp) => bp.depth))
  },
  getActivityEffortCategoryData: (_state) => (id, businessProcessId) => {
    let tasks = []
    let categories = []

    if (businessProcessId && typeof businessProcessId !== 'number') {
      // When businessProcessId is given and it's not a number, fetch data for this specific process
      const specificProcess = _state.operatingModel[businessProcessId]
      if (specificProcess) {
        tasks = specificProcess.meta.tasks
        categories = tasks.map((task) => task.name)
      }
    } else if (businessProcessId === 1) {
      // When businessProcessId is 1, fetch all processes
      Object.values(_state.operatingModel).forEach((bp) => {
        if (businessProcessId && bp.depth > 1) {
          tasks = tasks.concat(bp.meta?.tasks)
        }
      })

      categories = ['All Activities']
    } else {
      const sortedKeys = Object.keys(_state.operatingModel).sort((a, b) => {
        /**
         * Parses a key and returns the numeric part.
         *
         * @param {string} key - The key to parse.
         * @returns {number} The numeric part of the key.
         */
        const getKeyNumber = (key) => parseInt(key.split('_')[1], 10)

        const parentA = _state.operatingModel[a].parent
        const parentB = _state.operatingModel[b].parent

        const numA = getKeyNumber(a)
        const numB = getKeyNumber(b)

        const parentNumA = parentA === 'p_operating_model' ? -1 : getKeyNumber(parentA)
        const parentNumB = parentB === 'p_operating_model' ? -1 : getKeyNumber(parentB)

        // First, sort by parent number
        if (parentNumA !== parentNumB) {
          return parentNumA - parentNumB
        }

        // If parents are the same, sort by key number
        return numA - numB
      })

      // When businessProcessId is a number, fetch all processes at the specified depth
      sortedKeys.forEach((key) => {
        const bp = _state.operatingModel[key]
        if (businessProcessId && bp.depth === businessProcessId) {
          tasks = tasks.concat(bp.meta.tasks)
          categories.push(bp.name)
        }
      })
    }

    const activityMap = {}
    let totalFTE = 0
    let totalCost = 0
    const totalByActivity = {}
    const totalByCategory = new Array(categories.length).fill(0)

    /**
     * Calculates the Full-Time Equivalent (FTE) based on the provided positions.
     *
     * @param {Array} positions - The array of positions to calculate FTE for.
     * @returns {number} - The calculated FTE value.
     */
    const calculateFTE = (positions) => {
      // Filter out positions with empty roles
      const validPositions = positions.filter((position) => position.role !== '')

      // Return 0 if no valid positions
      if (validPositions.length === 0) return 0

      // Calculate the total FTE
      return validPositions.reduce((sum, { role, percentAllocation }) => {
        const roleFTE = _state.roleFTEMap[role] || 0 // Use 0 if roleFTE is undefined
        const calculatedFTE = Big(percentAllocation).div(100).times(roleFTE)

        return Number(Big(sum).plus(calculatedFTE).toFixed(2))
      }, 0)
    }

    if (typeof businessProcessId !== 'number') {
      tasks.forEach((task) => {
        const { activityCategory, positions, cost } = task
        const valueToAdd = id === 'fte' ? calculateFTE(positions) : cost

        if (id === 'fte') {
          totalFTE = Number(Big(totalFTE).plus(valueToAdd).toFixed(2))
        } else {
          totalCost = Number(Big(totalCost).plus(valueToAdd).toFixed(2))
        }

        if (!activityMap[activityCategory]) {
          activityMap[activityCategory] = {
            values: new Array(categories.length).fill(0),
            percentages: new Array(categories.length).fill(0)
          }
        }

        let categoryIndex = -1
        if (businessProcessId) {
          categoryIndex = tasks.indexOf(task)
        } else {
          const taskBP = Object.values(_state.operatingModel).find((bp) =>
            bp.meta?.tasks?.includes(task)
          )
          if (taskBP) {
            categoryIndex = categories.indexOf(taskBP.name)
          }
        }

        if (categoryIndex !== -1) {
          activityMap[activityCategory].values[categoryIndex] = Number(
            Big(activityMap[activityCategory].values[categoryIndex]).plus(valueToAdd).toFixed(2)
          )

          // Initialize if not already
          totalByActivity[categoryIndex] = totalByActivity[categoryIndex] || 0
          totalByActivity[categoryIndex] = Number(
            Big(totalByActivity[categoryIndex]).plus(valueToAdd).toFixed(2)
          )
        }
      })
    } else if (businessProcessId === 1) {
      tasks.forEach((task) => {
        const { activityCategory, positions, cost } = task
        const valueToAdd = id === 'fte' ? calculateFTE(positions) : cost

        if (!activityMap[activityCategory]) {
          activityMap[activityCategory] = {
            values: new Array(categories.length).fill(0),
            percentages: new Array(categories.length).fill(0)
          }
        }

        activityMap[activityCategory].values[0] = Number(
          Big(activityMap[activityCategory].values[0]).plus(valueToAdd).toFixed(2)
        )

        totalByCategory[0] = Number(Big(totalByCategory[0]).plus(valueToAdd).toFixed(2))
      })
    } else {
      tasks.forEach((task) => {
        const { activityCategory, positions, cost } = task
        const valueToAdd = id === 'fte' ? calculateFTE(positions) : cost

        if (!activityMap[activityCategory]) {
          activityMap[activityCategory] = {
            values: new Array(categories.length).fill(0),
            percentages: new Array(categories.length).fill(0)
          }
        }

        // Determine the category index from the task's associated business process name
        const categoryIndex = categories.indexOf(
          _state.operatingModel[_state.operatingModel[task.id].parent].name
        )
        if (categoryIndex !== -1) {
          activityMap[activityCategory].values[categoryIndex] = Number(
            Big(activityMap[activityCategory].values[categoryIndex]).plus(valueToAdd).toFixed(2)
          )
          totalByCategory[categoryIndex] = Number(
            Big(totalByCategory[categoryIndex]).plus(valueToAdd).toFixed(2)
          )
        }
      })
    }

    if (typeof businessProcessId !== 'number') {
      const total = id === 'fte' ? totalFTE : totalCost
      Object.keys(activityMap).forEach((activityCategory) => {
        activityMap[activityCategory].percentages = activityMap[activityCategory].values.map(
          (value) => (total > 0 ? Number(Big(value).div(total).times(100).toFixed(2)) : 0)
        )
      })
    } else {
      const total = totalByCategory.reduce((a, b) => Big(a).plus(b), Big(0)).toNumber()

      Object.keys(activityMap).forEach((activityCategory) => {
        activityMap[activityCategory].percentages = activityMap[activityCategory].values.map(
          (value) => {
            return total > 0 ? Number(Big(value).div(total).times(100).toFixed(2)) : 0
          }
        )
      })
    }

    let groupPercentageData = []
    if (typeof businessProcessId !== 'number') {
      // Correct calculation for group percentages
      groupPercentageData = Object.keys(activityMap).map((activityCategory) => {
        const percentages = activityMap[activityCategory].values.map((value, index) => {
          const groupTotal = totalByActivity[index] // Total for the category within the specific business process
          return groupTotal > 0 ? Big(value).div(groupTotal).times(100) : 0
        })

        return {
          name: activityCategory === 'undefined' ? 'No category' : activityCategory,
          data: percentages
        }
      })
    } else if (businessProcessId === 1) {
      groupPercentageData = Object.keys(activityMap).map((activityCategory) => {
        return {
          name: activityCategory === 'undefined' ? 'No category' : activityCategory,
          data: activityMap[activityCategory].values.map((value) => {
            const cost = totalByCategory[0]
            return cost > 0 ? Big(value).div(cost).times(100) : 0
          })
        }
      })
    } else {
      groupPercentageData = Object.keys(activityMap).map((activityCategory) => {
        return {
          name: activityCategory === 'undefined' ? 'No category' : activityCategory,
          data: activityMap[activityCategory].values.map((value, index) => {
            const groupTotal = totalByCategory[index]
            return groupTotal > 0 ? Big(value).div(groupTotal).times(100) : 0
          })
        }
      })
    }

    return {
      categories,
      absoluteData: Object.entries(activityMap).map(([name, { values }]) => ({
        name: name === 'undefined' ? 'No category' : name,
        data: values
      })),
      totalPercentageData: Object.entries(activityMap).map(([name, { percentages }]) => ({
        name: name === 'undefined' ? 'No category' : name,
        data: percentages
      })),
      groupPercentageData
    }
  },
  getRoleEffortCategoryData: (_state) => (id, roles, combineRoles) => {
    // Simplify task aggregation
    const tasks = Object.values(_state.operatingModel)
      .filter((bp) => bp.depth > 1)
      .flatMap((bp) => bp.meta.tasks)

    // Initialize Sets for unique categories
    const roleCategories =
      combineRoles && roles.length === 0 ? new Set(['All Roles']) : new Set(roles)
    const activityCategories = new Set()

    // Build activityMap considering only specified roles, and track total efforts
    let totalEffort = 0
    const activityMap = tasks.reduce((acc, { activityCategory, positions }) => {
      activityCategories.add(activityCategory)

      positions
        .filter(({ role }) => role !== '')
        .forEach(({ role, cost, percentAllocation }) => {
          if (combineRoles && roles.length === 0) {
            // If combining roles and no specific roles are provided
            const combinedRole = 'All Roles'
            const valueToAdd =
              id === 'cost'
                ? Number(Big(cost).times(percentAllocation).div(100).toFixed(2))
                : Number(Big(percentAllocation).div(100).times(_state.roleFTEMap[role]).toFixed(2))
            totalEffort = Number(Big(totalEffort).plus(valueToAdd).toFixed(2))

            acc[combinedRole] = acc[combinedRole] || {}
            acc[combinedRole][activityCategory] = Number(
              Big(acc[combinedRole][activityCategory] ?? 0)
                .plus(valueToAdd)
                .toFixed(2)
            )
          } else if (roles.includes(role)) {
            // Ensure we're only dealing with specified roles
            const valueToAdd =
              id === 'cost'
                ? Number(Big(cost).times(percentAllocation).div(100).toFixed(2))
                : Number(Big(percentAllocation).div(100).times(_state.roleFTEMap[role]).toFixed(2))
            totalEffort = Number(Big(totalEffort).plus(valueToAdd).toFixed(2))

            acc[role] = acc[role] || {}
            acc[role][activityCategory] = Number(
              Big(acc[role][activityCategory] ?? 0)
                .plus(valueToAdd)
                .toFixed(2)
            )
          }
        })

      return acc
    }, {})

    // Generate graph data ensuring all specified roles are included
    const graphData = Array.from(activityCategories).map((activityCategory) => ({
      name: activityCategory ?? 'No category',
      data: Array.from(roleCategories).map((role) =>
        Number(Big(activityMap[role]?.[activityCategory] ?? 0).toFixed(2))
      )
    }))

    // Compute percentages for each role's effort within each activity category
    const percentageData = Array.from(activityCategories).map((activityCategory) => ({
      name: activityCategory ?? 'No category',
      data: Array.from(roleCategories).map((role) => {
        const roleEffort = activityMap[role]?.[activityCategory] ?? 0
        return totalEffort > 0 ? Number(Big(roleEffort).div(totalEffort).times(100).toFixed(2)) : 0
      })
    }))

    // Calculate groupPercentageData with the focus on activityCategories
    const groupPercentageData = Array.from(activityCategories).map((activityCategory) => {
      const percentages = Array.from(roleCategories).map((role) => {
        const roleActivities = activityMap[role] || {}
        const roleTotalEffort = Object.values(roleActivities).reduce(
          (acc, curr) => Number(Big(acc).plus(curr).toFixed(2)),
          0
        )
        const activityEffort = roleActivities[activityCategory] || 0
        return roleTotalEffort > 0
          ? Number(Big(activityEffort).div(roleTotalEffort).times(100).toFixed(2))
          : 0
      })

      return {
        name: activityCategory ?? 'No category',
        data: percentages
      }
    })

    return {
      categories: Array.from(roleCategories),
      absoluteData: graphData,
      totalPercentageData: percentageData,
      groupPercentageData
    }
  },
  getAllChildrenIds: (_state) => (id) => {
    const children = []
    const queue = [id]
    while (queue.length > 0) {
      const currentId = queue.shift()
      const currentTask = _state.operatingModel[currentId]
      if (currentTask.meta?.tasks) {
        currentTask.meta.tasks.forEach((task) => {
          children.push(task.id)
          queue.push(task.id)
        })
      }
    }
    return children
  },
  getAllUniqueDepths: (_state) => {
    // Extract all depths and convert them into a set to remove duplicates.
    const uniqueDepths = new Set(Object.values(_state.operatingModel).map((bp) => bp.depth))

    // Filter out depths with a value of 1, subtract 1 from each remaining depth,
    // and sort the resulting array in descending order.
    return Array.from(uniqueDepths)
      .filter((depth) => depth > 1)
      .map((depth) => depth - 1)
      .sort((a, b) => a - b)
  },
  getMaxPositions: (_state) => {
    return Math.max(
      ...Object.values(_state.operatingModel).flatMap((bp) => {
        if (bp?.meta?.tasks && bp?.meta?.tasks.length > 0) {
          return bp?.meta?.tasks.map((task) => task.positions?.length || 0)
        } else {
          return [bp?.positions?.length || 0]
        }
      })
    )
  },
  getRoleFTEMap: (_state) => {
    return _state.roleFTEMap
  },
  getIdAndNames: (_state) => {
    return Object.keys(_state.operatingModel).map((key) => {
      return { id: key, name: _state.operatingModel[key].name }
    })
  }
}

const mutations = {
  addBusinessProcess(_state, { newId, parentId }) {
    const parentDepth = _state.operatingModel[parentId]?.depth ?? 1
    _state.operatingModel[newId] = {
      name: '',
      meta: { tasks: [] },
      parent: parentId,
      depth: parentDepth + 1
    }
    if (parentId !== 'p_operating_model' && parentId !== '_root') {
      _state.operatingModel[parentId].meta.tasks.push({
        id: newId,
        name: '',
        cost: 0,
        positions: [],
        expanded: false
      })
    }
  },
  removeBusinessProcess(_state, id) {
    // Helper function to recursively remove tasks
    function recursiveRemove(taskId) {
      // Check if the task exists in the operating model
      const task = _state.operatingModel[taskId]
      if (task) {
        // If the task has child tasks
        if (task.meta?.tasks?.length > 0) {
          // Recursively remove each child task
          task.meta.tasks.slice().forEach((childTask) => {
            recursiveRemove(childTask.id)
          })
        }

        // If the task has a parent (and it's not the root or the operating model itself)
        if (task.parent && task.parent !== '_root' && task.parent !== 'p_operating_model') {
          const parent = _state.operatingModel[task.parent]
          const index = parent?.meta?.tasks?.findIndex((t) => t.id === taskId)
          if (index !== -1) {
            parent.meta.tasks.splice(index, 1)
          }
        }

        // Delete the task from the operating model
        delete _state.operatingModel[taskId]
      }
    }

    // Start the recursive removal process with the provided id
    recursiveRemove(id)
  },
  duplicateBusinessProcess(_state, { oldId, newId }) {
    // Deep clone the original business process to start the duplication
    const clonedObject = _.cloneDeep(_state.operatingModel[oldId])
    // Initialize the newId object in the operatingModel
    _state.operatingModel[newId] = clonedObject
    _state.operatingModel[newId].parent = _state.operatingModel[oldId].parent
    _state.operatingModel[newId].depth = _state.operatingModel[oldId].depth

    const parentID = _state.operatingModel[oldId].parent
    if (parentID !== 'p_operating_model' && parentID !== '_root') {
      const parentTasks = _state.operatingModel[parentID].meta.tasks
      const taskIndex = parentTasks.findIndex((task) => task.id === oldId)
      if (taskIndex !== -1) {
        // Duplicate the task within the parent's meta.tasks with the newId
        const newTask = { ...parentTasks[taskIndex], id: newId }
        parentTasks.push(newTask)
      }
    }

    // Recursive function for duplication, corrected to match intended usage
    function recursiveDuplicate(oldTaskId, newParentId) {
      const idParts = oldTaskId.split('_')
      const oldTaskNumericIdPart = parseInt(idParts[1], 10)
      // Here, it's assumed createActivityAnalysisId internally manages how IDs are incremented
      const newTaskId = oldTaskId === oldId ? newId : createActivityAnalysisId(oldTaskNumericIdPart)

      const oldTask = _state.operatingModel[oldTaskId]

      if (newTaskId !== newId) {
        _state.operatingModel[newTaskId] = _.cloneDeep(oldTask)
        _state.operatingModel[newTaskId].parent = newParentId
      }

      oldTask.meta?.tasks?.forEach((childTask) => {
        // Directly call recursiveDuplicate without an extra parameter for ID incrementing
        const childNewId = recursiveDuplicate(childTask.id, newTaskId)
        const childIndex = _state.operatingModel[newTaskId].meta?.tasks?.findIndex(
          (t) => t.id === childTask.id
        )

        if (childIndex !== -1) {
          _state.operatingModel[newTaskId].meta.tasks[childIndex].id = childNewId
        }
      })

      return newTaskId // Return the new ID for use by the parent
    }

    // Kick off the recursive duplication
    recursiveDuplicate(oldId, _state.operatingModel[oldId].parent)
  },
  updateBusinessProcessName(_state, { id, name }) {
    _state.operatingModel[id].name = name
  },
  updateExpandedState(_state, { id, index }) {
    _state.operatingModel[id].meta.tasks[index].expanded =
      !_state.operatingModel[id].meta.tasks[index].expanded
  },
  removeTask(_state, { id, index }) {
    const operatingModel = _state.operatingModel

    // Find the ID of the task to be removed
    const removeNodeId = operatingModel[id].meta.tasks[index]?.id
    if (!removeNodeId) return

    const processedTasks = new Set()

    // Function to recursively remove a task and its children
    function recursiveRemove(taskId) {
      if (processedTasks.has(taskId)) return
      processedTasks.add(taskId)

      const task = operatingModel[taskId]
      if (!task) return

      // Recursively remove child tasks
      task.meta?.tasks?.forEach((childTask) => recursiveRemove(childTask.id))

      // Remove the task from its parent's meta.tasks array
      if (task.parent && task.parent !== id) {
        // Skip for the initial task
        const parent = operatingModel[task.parent]
        const taskIndex = parent?.meta?.tasks?.findIndex((t) => t.id === taskId)
        if (taskIndex > -1) {
          parent.meta.tasks.splice(taskIndex, 1)
        }
      }

      delete operatingModel[taskId]
    }

    recursiveRemove(removeNodeId)

    // Remove the initial task from its parent's meta.tasks array
    operatingModel[id].meta.tasks.splice(index, 1)
  },
  duplicateTask(_state, { id, index }) {
    const maxId = parseInt(
      _state.operatingModel[id].meta.tasks[
        _state.operatingModel[id].meta.tasks.length - 1
      ].id.split('_')[1],
      10
    )

    // Generate a new ID for the duplicated task
    const newTaskId = createActivityAnalysisId(maxId + 1)

    // Clone the task specified by id and index within its parent's meta.tasks
    const duplicatedTask = _.cloneDeep(_state.operatingModel[id].meta.tasks[index])
    duplicatedTask.id = newTaskId // Assign the new ID to the duplicated task

    // Insert the duplicated task back into the parent's task list
    _state.operatingModel[id].meta.tasks.push(duplicatedTask)

    // Begin recursive duplication of the associated business process or task structure
    // The old task's ID is used to find the associated structure within the operating model
    const oldTaskId = _state.operatingModel[id].meta.tasks[index].id
    const clonedObject = _.cloneDeep(_state.operatingModel[oldTaskId])

    // Initialize the duplicated structure in the operatingModel with the new task ID
    _state.operatingModel[newTaskId] = clonedObject
    _state.operatingModel[newTaskId].parent = _state.operatingModel[oldTaskId].parent
    _state.operatingModel[newTaskId].depth = _state.operatingModel[oldTaskId].depth

    // Recursive duplication function
    function recursiveDuplicate(oldTaskIdParam, newParentId) {
      const oldTask = _state.operatingModel[oldTaskIdParam]

      oldTask.meta?.tasks?.forEach((childTask) => {
        const childIdNumericPart = parseInt(childTask.id.split('_')[1], 10)
        const childNewId = createActivityAnalysisId(childIdNumericPart)

        // Clone the child task and update its ID and parent
        const clonedChildTask = _.cloneDeep(childTask)
        clonedChildTask.id = childNewId
        clonedChildTask.parent = newParentId // Update parent ID to new parent

        // Ensure the operatingModel at newParentId has a meta object, and initialize tasks if not present
        if (!_state.operatingModel[newParentId].meta) {
          _state.operatingModel[newParentId].meta = { tasks: [] }
        }
        // Add the cloned task to the model
        _state.operatingModel[newParentId].meta.tasks.push(clonedChildTask)

        // Recursive call for nested tasks
        recursiveDuplicate(childTask.id, childNewId)
      })
    }

    // Kick off recursive duplication for the task and its associated structure
    recursiveDuplicate(newTaskId, id)
  },
  addTask(_state, { id }) {
    const parentDepth = _state.operatingModel[id].depth
    const maxId =
      _state.operatingModel[id].meta.tasks.length > 0
        ? parseInt(
            _state.operatingModel[id].meta.tasks[
              _state.operatingModel[id].meta.tasks.length - 1
            ].id.split('_')[1],
            10
          )
        : 0
    const newId = createActivityAnalysisId(maxId + 1)
    _state.operatingModel[id].meta.tasks.push({
      id: newId,
      name: '',
      cost: 0,
      positions: [],
      expanded: false
    })
    _state.operatingModel[newId] = {
      name: '',
      meta: { tasks: [] },
      parent: id,
      depth: parentDepth + 1
    }
  },
  updateTaskName(_state, { id, index, name }) {
    _state.operatingModel[id].meta.tasks[index].name = name
  },
  expandAllTasks(_state) {
    Object.keys(_state.operatingModel).forEach((key) => {
      _state.operatingModel[key].meta.tasks.forEach((task) => {
        task.expanded = true
      })
    })
  },
  collapseAllTasks(_state) {
    Object.keys(_state.operatingModel).forEach((key) => {
      _state.operatingModel[key].meta.tasks.forEach((task) => {
        task.expanded = false
      })
    })
  },
  updateTimeAllocated(_state, { id, taskIndex, personIndex, time }) {
    _state.operatingModel[id].meta.tasks[taskIndex].cost -= parseFloat(
      (
        (_state.operatingModel[id].meta.tasks[taskIndex].people[personIndex].cost *
          _state.operatingModel[id].meta.tasks[taskIndex].people[personIndex].time) /
        100
      ).toFixed(2)
    )
    _state.operatingModel[id].meta.tasks[taskIndex].cost += parseFloat(
      (
        (_state.operatingModel[id].meta.tasks[taskIndex].people[personIndex].cost * time) /
        100
      ).toFixed(2)
    )
    _state.operatingModel[id].meta.tasks[taskIndex].people[personIndex].time = time
  },
  setOperatingModel(_state, operatingModel) {
    _state.operatingModel = operatingModel?.data ?? getDefaultState().operatingModel
  },
  updateActivityDetails(_state, { id, activityIndex, activityDetails }) {
    Object.assign(_state.operatingModel[id].meta.tasks[activityIndex], activityDetails)
  },
  addPositions(_state, { id, activityIndex, positions, headcount }) {
    const model = _state.operatingModel[id]
    if (!model) {
      console.error(`Invalid id: ${id}. This id does not exist in the operating model.`)
      return
    }

    if (!Array.isArray(positions)) {
      console.error('Invalid positions: Expected an array.')
      return
    }

    // Calculate total cost for valid positions
    const calculateCost = (positions) => {
      return positions.reduce((total, { cost, percentAllocation }) => {
        if (typeof cost !== 'number' || typeof percentAllocation !== 'number') {
          console.error('Invalid position data: cost and percentAllocation must be numbers.')
          return total
        }
        return total + parseFloat(((cost * percentAllocation) / 100).toFixed(2))
      }, 0)
    }

    // Update task or node positions
    if (activityIndex !== null && activityIndex !== undefined) {
      const tasks = model.meta?.tasks
      if (!tasks || activityIndex < 0 || activityIndex >= tasks.length) {
        console.error(`Invalid activityIndex: ${activityIndex}. This index is out of bounds.`)
        return
      }

      const task = tasks[activityIndex]
      task.positions = positions
      task.cost = calculateCost(positions)
      task.headcount = headcount
    } else {
      model.positions = positions
      model.headcount = headcount
    }
  },
  addActivityCategorySuggestion(_state, activityCategory) {
    if (_state.operatingModel.p_operating_model.activityCategorySuggestions) {
      _state.operatingModel.p_operating_model.activityCategorySuggestions.push(activityCategory)
    } else {
      _state.operatingModel.p_operating_model.activityCategorySuggestions = [activityCategory]
    }
  },
  addChangeEnablerSuggestion(_state, changeEnabler) {
    if (_state.operatingModel.p_operating_model.changeEnablerSuggestions) {
      _state.operatingModel.p_operating_model.changeEnablerSuggestions.push(changeEnabler)
    } else {
      _state.operatingModel.p_operating_model.changeEnablerSuggestions = [changeEnabler]
    }
  },
  moveNode(_state, { sourceId, targetId }) {
    // Check if the source and target are the same or if the source is a parent of the target
    if (sourceId === targetId || _state.operatingModel[sourceId].parent === targetId) {
      return
    }

    const keysOfParent = Object.keys(_state.operatingModel).filter(
      (key) => _state.operatingModel[key].parent === targetId
    )
    const ids = keysOfParent.map((key) => {
      const parts = key.split('_')
      return parseInt(parts[1], 10)
    })
    const newId = createActivityAnalysisId((ids.length > 0 ? Math.max(...ids) : 0) + 1)

    _state.operatingModel[newId] = _.cloneDeep(_state.operatingModel[sourceId])
    _state.operatingModel[newId].parent = targetId
    _state.operatingModel[newId].depth = _state.operatingModel[targetId].depth + 1

    // Update the parent of the children of the source node
    const childNodesMap = new Map(
      Object.keys(_state.operatingModel)
        .filter((key) => _state.operatingModel[key].parent === sourceId)
        .map((key) => [key, _state.operatingModel[key]])
    )
    childNodesMap.forEach((child) => (child.parent = newId))

    /**
     * Updates the depths of nodes in the operating model.
     * @param {string[]} nodes - An array of node IDs.
     * @param {number} parentDepth - The depth of the parent node.
     */
    const updateDepths = (nodes, parentDepth) => {
      nodes.forEach((node) => {
        _state.operatingModel[node].depth = parentDepth + 1
        if (_state.operatingModel[node].meta?.tasks) {
          updateDepths(
            _state.operatingModel[node].meta.tasks.map((t) => t.id),
            parentDepth + 1
          )
        }
      })
    }

    updateDepths([newId], _state.operatingModel[targetId].depth)

    // Update the parent's meta tasks
    if (targetId !== 'p_operating_model') {
      _state.operatingModel[targetId].meta.tasks.push({
        id: newId,
        name: _state.operatingModel[newId].name,
        cost:
          _state.operatingModel[sourceId].depth > 2
            ? _state.operatingModel[_state.operatingModel[sourceId].parent].meta.tasks.find(
                (task) => task.id === sourceId
              ).cost
            : 0,
        positions:
          _state.operatingModel[sourceId].depth > 2
            ? _state.operatingModel[_state.operatingModel[sourceId].parent].meta.tasks.find(
                (task) => task.id === sourceId
              ).positions
            : [],
        expanded: false
      })
    }

    // Remove the source node
    if (_state.operatingModel[sourceId].depth > 2) {
      const parent = _state.operatingModel[_state.operatingModel[sourceId].parent]
      const index = parent.meta.tasks.findIndex((task) => task.id === sourceId)
      if (index !== -1) {
        parent.meta.tasks.splice(index, 1)
      }
    }
    delete _state.operatingModel[sourceId]
  },
  setRoleFTEMap(_state, roleFTEMap) {
    _state.roleFTEMap = roleFTEMap
  }
}

const actions = {
  async fetchOperatingModel({ commit, rootGetters }, { boardId, planId }) {
    const operatingModel = await getOperatingModel({ boardId, planId })

    if (!operatingModel || !operatingModel.data) {
      operatingModel.data = getDefaultState().operatingModel
    }

    Object.values(operatingModel?.data ?? {}).forEach((item) => {
      item.meta?.tasks?.forEach((task) => {
        task.expanded = false
      })
    })

    if (!operatingModel.data.p_operating_model.name) {
      operatingModel.data.p_operating_model.name = rootGetters.getBoard(planId).boardName ?? ''
    }

    /**
     * Calculates the total Full-Time Equivalent (FTE) for each role.
     *
     * @returns {Object} An object containing the total FTE for each role.
     */
    async function calculateTotalFTE() {
      const customFields = rootGetters['customFields/types'](boardId)
      const fteField = customFields.find((field) => field.name === 'FTE')
      let customFieldValues = []
      if (fteField) {
        customFieldValues = await fetchValues({ boardId: planId })
      }
      const allRoles = rootGetters['people/roleList'](planId)

      // Initialize the hash map to store total FTE for each role
      const roleFTEMap = {}
      const peopleById = rootGetters['people/peopleById'](planId)
      const activeEmployees = rootGetters['people/activeEmployees'](planId)
      const activeEmployeesByRole = _.keyBy(activeEmployees, 'role')

      // Iterate over each role
      for (const role of allRoles) {
        // Filter custom field values for the current role

        let totalFTE = 0
        if (fteField) {
          const fteCustomFieldValues = customFieldValues.filter(
            (val) => val.fieldId === fteField?.id && peopleById?.[val.personId]?.role === role
          )

          // Sum up the FTE values for the current role
          totalFTE = fteCustomFieldValues.reduce((sum, val) => {
            const fteValue = parseFloat(val.data)
            return sum + (isNaN(fteValue) ? 0 : fteValue)
          }, 0)
        }

        // Assign the total FTE to the role in the hash map
        roleFTEMap[role] = totalFTE === 0 ? activeEmployeesByRole[role]?.length : totalFTE
      }

      return roleFTEMap
    }

    const roleFTEMap = await calculateTotalFTE()

    commit('setRoleFTEMap', roleFTEMap)
    commit('setOperatingModel', operatingModel)
  },
  async saveModel({ commit }, { boardId, planId }) {
    const data = await saveOperatingModel({
      boardId,
      planId,
      entry: { id: `${boardId}_${planId}`, planId, data: state.operatingModel, boardId }
    })
    commit('setOperatingModel', data)
  },
  async addBusinessProcess({ commit }, payload) {
    await commit('addBusinessProcess', payload)
  },
  async removeBusinessProcess({ commit }, id) {
    await commit('removeBusinessProcess', id)
  },
  async duplicateBusinessProcess({ commit }, { oldId, newId }) {
    await commit('duplicateBusinessProcess', { oldId, newId })
  },
  async updateBusinessProcessName({ commit }, payload) {
    await commit('updateBusinessProcessName', payload)
  },
  async updateExpandedState({ commit }, payload) {
    await commit('updateExpandedState', payload)
  },
  async removeTask({ commit }, payload) {
    await commit('removeTask', payload)
  },
  async duplicateTask({ commit }, payload) {
    await commit('duplicateTask', payload)
  },
  async addTask({ commit }, payload) {
    await commit('addTask', payload)
  },
  async updateTaskName({ commit }, payload) {
    await commit('updateTaskName', payload)
  },
  async expandAllTasks({ commit }) {
    await commit('expandAllTasks')
  },
  async collapseAllTasks({ commit }) {
    await commit('collapseAllTasks')
  },
  async updateTimeAllocated({ commit }, payload) {
    await commit('updateTimeAllocated', payload)
  },
  async updateActivityDetails({ commit }, payload) {
    await commit('updateActivityDetails', payload)
  },
  async addPositions({ commit }, payload) {
    await commit('addPositions', payload)
  },
  async addActivityCategorySuggestion({ commit }, payload) {
    await commit('addActivityCategorySuggestion', payload)
  },
  async addChangeEnablerSuggestion({ commit }, payload) {
    await commit('addChangeEnablerSuggestion', payload)
  },
  async moveNode({ commit }, payload) {
    await commit('moveNode', payload)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {}
}
